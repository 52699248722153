<template>
  <div class="ps-5 pe-5">
    <div class="row">
      <div class="col-12">
        <div class="dashboard-user-messages">
          <div class="h-auto">

            <div class="row h-100 align-items-center p-0 m-0 pt-3 pb-4">
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 m-0">
                <div class="card shadow sm">
                  <div class="card-body user-messages-wrapper p-0">

                    <div class="row m-0 p-0">
                      <div class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 m-0 p-0 user-messages-container">
<!--                      <div class="col-3 col-xs-3 col-sm-3 col-md-3 col-lg-4 m-0 p-0 user-messages-container">-->
                        <ul class="nav nav-tabs justify-content-center" id="chat-room-list">
                          <li class="nav-item">
                            <a href="#properties" id="propertiesTabUrl" class="nav-link active" data-bs-toggle="tab">Properties</a>
                          </li>
                          <li class="nav-item">
                            <a href="#services" id="servicesTabUrl" class="nav-link" data-bs-toggle="tab">Services</a>
                          </li>
<!--                          <li class="nav-item">-->
<!--                            <a href="#all" class="nav-link" data-bs-toggle="tab">All</a>-->
<!--                          </li>-->
                        </ul>
                        <div class="tab-content">
                          <div class="tab-pane fade show active user-messages-groups-container scroll-width-thin" id="properties">
                            <div v-for="(propertyMessageGroup, index) in messagesPropertiesGroups"
                                 :key="index"
                                 class="chat-room"
                            >
                              <div v-if="checkSender(user.id, propertyMessageGroup.to_user_id, propertyMessageGroup.from_user_id)">
                                <div :class="activeChatRoom === propertyMessageGroup.id ? 'chat-list-container active' : 'chat-list-container'"
                                     @click="getMessages(propertyMessageGroup.id)"
                                     :id="'chatRoom' + propertyMessageGroup.id"
                                >
                                  <!-- property.property_rent_type !== 1 means not N/A -->
                                  <div class="chat-list-subject d-none d-lg-block">
                                    <div v-if="showPriceRange(propertyMessageGroup.property)">
                                      <span class="position-relative" data-tooltip="tooltip" @click="openAdDetails(propertyMessageGroup.property_id)">
                                        <font-awesome-icon icon="eye" class="icon-fa min-width-30" style="color: mediumpurple;"/>
                                        <span class="tooltiptext">Click to open related advertise</span>
                                      </span>
                                      <font-awesome-icon icon="pound-sign" class="icon-fa"/>
                                      <span v-if="showHourPriceRange(propertyMessageGroup.property)" class="price-color"> {{ calculateHourPriceRange(propertyMessageGroup.property) }} </span>
                                      <span v-if="showHourPriceRange(propertyMessageGroup.property)" class="fw-bold"> per hour </span> <br v-if="showHourPriceRange(propertyMessageGroup.property)"/>
                                      <span v-if="showNightPriceRange(propertyMessageGroup.property)" class="price-color"> {{ calculateNightPriceRange(propertyMessageGroup.property) }} </span>
                                      <span v-if="showNightPriceRange(propertyMessageGroup.property)" class="fw-bold"> per night </span>
                                      <!-- This only for Let Long-Term Rooms -->
                                      <span v-if="showWeekPriceRange(propertyMessageGroup.property)" class="price-color"> {{ calculateWeekPriceRange(propertyMessageGroup.property) }} </span>
                                      <span v-if="showWeekPriceRange(propertyMessageGroup.property)" class="fw-bold"> per week </span>
                                      <span v-if="showMonthPriceRange(propertyMessageGroup.property)" class="price-color"> {{ calculateMonthPriceRange(propertyMessageGroup.property) }} </span>
                                      <span v-if="showMonthPriceRange(propertyMessageGroup.property)" class="fw-bold"> per month </span>
                                    </div>
                                    <div v-else>
                                      <span class="position-relative" data-tooltip="tooltip" @click="openAdDetails(propertyMessageGroup.property_id)">
                                        <font-awesome-icon icon="eye" class="icon-fa min-width-30" style="color: mediumpurple;"/>
                                        <span class="tooltiptext">Click to open related advertise</span>
                                      </span>
                                      <font-awesome-icon icon="pound-sign" class="icon-fa"/>
                                      <span class="price-color"> {{ convertNumberToCommas(propertyMessageGroup.property.price) }} </span>
                                      <span v-if="propertyMessageGroup.property.is_rent || checkAdIsLetLongTermOrStudio(propertyMessageGroup.property)" class="fw-bold"> per {{ propertyMessageGroup.property.property_rent_type.type }} </span>
                                    </div>
<!--                                    <span class="price-color"> {{ convertNumberToCommas(propertyMessageGroup.property.price) }}</span>-->
<!--                                    {{((propertyMessageGroup.property.property_rent_type && propertyMessageGroup.property.property_rent_type.id !== 1) ? (' per ' + propertyMessageGroup.property.property_rent_type.type) : '')}} -->
                                    {{propertyMessageGroup.property.property_category ? propertyMessageGroup.property.property_category.type : ''}}
                                    {{propertyMessageGroup.property.property_type ? propertyMessageGroup.property.property_type.type : ''}}
                                    <p>{{(propertyMessageGroup.property.address.full_address ? propertyMessageGroup.property.address.full_address : '')}}</p>
                                  </div>
                                  <div class="chat-list-person">
                                    <div class="row m-0 p-0">
                                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 m-0 p-0">
                                        <div v-if="!propertyMessageGroup.to_user_property.user.documents_user_images">
                                          <img :src="require(`@/assets/no-user-image.png`)"
                                               class="d-block img-fluid user-image"
                                               alt="..."
                                          >
                                        </div>
                                        <div v-else>
                                          <img :src="`${$s3bucket}${propertyMessageGroup.to_user_property.user.documents_user_images.file_path}`" @error="setUserDefaultImg"
                                               class="d-block img-fluid user-image"
                                               alt="..."
                                          >
                                        </div>
                                      </div>
                                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-8 m-0 p-0 mt-md-4 mt-lg-4">
                                        <p :class="notifications && (notifications.length > 0) && (checkNotificationsForNewMessages(propertyMessageGroup.id)) ? 'fw-bold user-name m-0' : 'user-name m-0'">
                                          {{propertyMessageGroup.to_user_property.name}} {{propertyMessageGroup.to_user_property.surname}}
                                        </p>
                                        <div v-if="notifications && (notifications.length > 0) && (checkNotificationsForNewMessages(propertyMessageGroup.id))"
                                             class="col-12 m-0 p-0"
                                        >
                                          <div class="new-message-animation">
                                            NEW
                                            <font-awesome-icon icon="envelope" class="icon-fa" style="color: #0d6efd;"/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div v-else>
                                <div :class="activeChatRoom === propertyMessageGroup.id ? 'chat-list-container active' : 'chat-list-container'"
                                     @click="getMessages(propertyMessageGroup.id)"
                                     :id="'chatRoom' + propertyMessageGroup.id"
                                >
                                  <!-- property.property_rent_type !== 1 means not N/A -->
                                  <div class="chat-list-subject d-none d-lg-block">
                                    <div v-if="showPriceRange(propertyMessageGroup.property)">
                                      <span class="position-relative" data-tooltip="tooltip" @click="openAdDetails(propertyMessageGroup.property_id)">
                                        <font-awesome-icon icon="eye" class="icon-fa min-width-30" style="color: mediumpurple;" />
                                        <span class="tooltiptext">Click to open related advertise</span>
                                      </span>
                                      <font-awesome-icon icon="pound-sign" class="icon-fa"/>
                                      <span v-if="showHourPriceRange(propertyMessageGroup.property)" class="price-color"> {{ calculateHourPriceRange(propertyMessageGroup.property) }} </span>
                                      <span v-if="showHourPriceRange(propertyMessageGroup.property)" class="fw-bold"> per hour </span> <br v-if="showHourPriceRange(propertyMessageGroup.property)"/>
                                      <span v-if="showNightPriceRange(propertyMessageGroup.property)" class="price-color"> {{ calculateNightPriceRange(propertyMessageGroup.property) }} </span>
                                      <span v-if="showNightPriceRange(propertyMessageGroup.property)" class="fw-bold"> per night </span>
                                      <!-- This only for Let Long-Term Rooms -->
                                      <span v-if="showWeekPriceRange(propertyMessageGroup.property)" class="price-color"> {{ calculateWeekPriceRange(propertyMessageGroup.property) }} </span>
                                      <span v-if="showWeekPriceRange(propertyMessageGroup.property)" class="fw-bold"> per week </span>
                                      <span v-if="showMonthPriceRange(propertyMessageGroup.property)" class="price-color"> {{ calculateMonthPriceRange(propertyMessageGroup.property) }} </span>
                                      <span v-if="showMonthPriceRange(propertyMessageGroup.property)" class="fw-bold"> per month </span>
                                    </div>
                                    <div v-else>
                                      <span class="position-relative" data-tooltip="tooltip" @click="openAdDetails(propertyMessageGroup.property_id)">
                                        <font-awesome-icon icon="eye" class="icon-fa min-width-30" style="color: mediumpurple;" />
                                        <span class="tooltiptext">Click to open related advertise</span>
                                      </span>
                                      <font-awesome-icon icon="pound-sign" class="icon-fa"/>
                                      <span class="price-color"> {{ convertNumberToCommas(propertyMessageGroup.property.price) }} </span>
                                      <span v-if="propertyMessageGroup.property.is_rent || checkAdIsLetLongTermOrStudio(propertyMessageGroup.property)" class="fw-bold"> per {{ propertyMessageGroup.property.property_rent_type.type }} </span>
                                    </div>

<!--                                    <font-awesome-icon icon="pound-sign" class="icon-fa"/>-->
<!--                                    <span class="price-color"> {{ convertNumberToCommas(propertyMessageGroup.property.price) }}</span>-->
<!--                                    {{((propertyMessageGroup.property.property_rent_type && propertyMessageGroup.property.property_rent_type.id !== 1) ? (' per ' + propertyMessageGroup.property.property_rent_type.type) : '')}} -->
                                    {{propertyMessageGroup.property.property_category ? propertyMessageGroup.property.property_category.type : ''}}
                                    {{propertyMessageGroup.property.property_type ? propertyMessageGroup.property.property_type.type : ''}}
                                    <p>{{(propertyMessageGroup.property.address.full_address ? propertyMessageGroup.property.address.full_address : '')}}</p>
                                  </div>
                                  <div class="chat-list-person">
                                    <div class="row m-0 p-0">
                                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 m-0 p-0">
                                        <div v-if="!propertyMessageGroup.from_user.user.documents_user_images">
                                          <img :src="require(`@/assets/no-user-image.png`)"
                                               class="d-block img-fluid user-image"
                                               alt="..."
                                          >
                                        </div>
                                        <div v-else>
                                          <img :src="`${$s3bucket}${propertyMessageGroup.from_user.user.documents_user_images.file_path}`" @error="setUserDefaultImg"
                                               class="d-block img-fluid user-image"
                                               alt="..."
                                          >
                                        </div>
                                      </div>
                                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-8 m-0 p-0 mt-md-4 mt-lg-4">
                                        <p :class="notifications && (notifications.length > 0) && (checkNotificationsForNewMessages(propertyMessageGroup.id)) ? 'fw-bold user-name m-0' : 'user-name m-0'">
                                          {{propertyMessageGroup.from_user.name}} {{propertyMessageGroup.from_user.surname}}
                                        </p>
                                        <div v-if="notifications && (notifications.length > 0) && (checkNotificationsForNewMessages(propertyMessageGroup.id))"
                                             class="col-12 m-0 p-0"
                                        >
                                          <div class="new-message-animation">
                                            NEW
                                            <font-awesome-icon icon="envelope" class="icon-fa" style="color: #0d6efd;"/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <div v-if="messagesPropertiesGroups && messagesPropertiesGroups.length === 0" class="chat-room-info p-2">
                              No new messages
                            </div>
                          </div>
                          <div class="tab-pane fade user-messages-groups-container" id="services">
                            <div v-for="(serviceMessageGroup, index) in messagesServicesGroups"
                                 :key="index"
                                 class="chat-room"
                            >
                              <div v-if="checkSender(user.id, serviceMessageGroup.to_user_id, serviceMessageGroup.from_user_id)">
                                <div :class="activeChatRoom === serviceMessageGroup.id ? 'chat-list-container active' : 'chat-list-container'"
                                     @click="getMessages(serviceMessageGroup.id)"
                                     :id="'chatRoom' + serviceMessageGroup.id"
                                >
                                  <div class="chat-list-subject d-none d-lg-block">
                                    <font-awesome-icon icon="pound-sign" class="icon-fa"/>
                                    <span class="price-color"> {{ convertNumberToCommas(serviceMessageGroup.service.price) }}</span>
                                    {{((serviceMessageGroup.service.service_payment_type && serviceMessageGroup.service.service_payment_type.id !== 1) ? (' per ' + serviceMessageGroup.service.service_payment_type.type) : '')}} -
                                    {{serviceMessageGroup.service.occupation ? serviceMessageGroup.service.occupation.type : ''}}
                                    <p>{{(serviceMessageGroup.service.address.full_address ? serviceMessageGroup.service.address.full_address : '')}}</p>
                                  </div>
                                  <div class="chat-list-person">
                                    <div class="row m-0 p-0">
                                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 m-0 p-0">
                                        <div v-if="!serviceMessageGroup.to_user_service.user.documents_user_images">
                                          <img :src="require(`@/assets/no-user-image.png`)"
                                               class="d-block img-fluid user-image"
                                               alt="..."
                                          >
                                        </div>
                                        <div v-else>
                                          <img :src="`${$s3bucket}${serviceMessageGroup.to_user_service.user.documents_user_images.file_path}`" @error="setUserDefaultImg"
                                               class="d-block img-fluid user-image"
                                               alt="..."
                                          >
                                        </div>
                                      </div>
                                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-8 m-0 p-0 mt-md-4 mt-lg-4">
                                        <p :class="notifications && (notifications.length > 0) && (checkNotificationsForNewMessages(serviceMessageGroup.id)) ? 'fw-bold user-name m-0' : 'user-name m-0'">
                                          {{serviceMessageGroup.to_user_service.name}} {{serviceMessageGroup.to_user_service.surname}}
                                        </p>
                                        <div v-if="notifications && (notifications.length > 0) && (checkNotificationsForNewMessages(serviceMessageGroup.id))"
                                             class="col-12 m-0 p-0"
                                        >
                                          <div class="new-message-animation">
                                            NEW
                                            <font-awesome-icon icon="envelope" class="icon-fa" style="color: #0d6efd;"/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div v-else>
                                <div :class="activeChatRoom === serviceMessageGroup.id ? 'chat-list-container active' : 'chat-list-container'"
                                     @click="getMessages(serviceMessageGroup.id)"
                                     :id="'chatRoom' + serviceMessageGroup.id"
                                >
                                  <div class="chat-list-subject d-none d-lg-block">
                                    <font-awesome-icon icon="pound-sign" class="icon-fa"/>
                                    <span class="price-color"> {{ convertNumberToCommas(serviceMessageGroup.service.price) }}</span>
                                    {{((serviceMessageGroup.service.service_payment_type && serviceMessageGroup.service.service_payment_type.id !== 1) ? (' per ' + serviceMessageGroup.service.service_payment_type.type) : '')}} -
                                    {{serviceMessageGroup.service.occupation ? serviceMessageGroup.service.occupation.type : ''}}
                                    <p>{{(serviceMessageGroup.service.address.full_address ? serviceMessageGroup.service.address.full_address : '')}}</p>
                                  </div>
                                  <div class="chat-list-person">
                                    <div class="row m-0 p-0">
                                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 m-0 p-0">
                                        <div v-if="!serviceMessageGroup.from_user.user.documents_user_images">
                                          <img :src="require(`@/assets/no-user-image.png`)"
                                               class="d-block img-fluid user-image"
                                               alt="..."
                                          >
                                        </div>
                                        <div v-else>
                                          <img :src="`${$s3bucket}${serviceMessageGroup.from_user.user.documents_user_images.file_path}`" @error="setUserDefaultImg"
                                               class="d-block img-fluid user-image"
                                               alt="..."
                                          >
                                        </div>
                                      </div>
                                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-8 m-0 p-0 mt-md-4 mt-lg-4">
                                        <p :class="notifications && (notifications.length > 0) && (checkNotificationsForNewMessages(serviceMessageGroup.id)) ? 'fw-bold user-name m-0' : 'user-name m-0'">
                                          {{serviceMessageGroup.from_user.name}} {{serviceMessageGroup.from_user.surname}}
                                        </p>
                                        <div v-if="notifications && (notifications.length > 0) && (checkNotificationsForNewMessages(serviceMessageGroup.id))"
                                             class="col-12 m-0 p-0"
                                        >
                                          <div class="new-message-animation">
                                            NEW
                                            <font-awesome-icon icon="envelope" class="icon-fa" style="color: #0d6efd;"/>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="messagesServicesGroups && messagesServicesGroups.length === 0" class="chat-room-info p-2">
                              No new messages
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="col-12 col-xs-12 col-sm-12 col-md-9 col-lg-9 m-0 p-0 user-messages-msg-container position-relative">
<!--                      <div class="col-9 col-xs-9 col-sm-9 col-md-9 col-lg-8 m-0 p-0 user-messages-msg-container position-relative">-->
                        <div v-if="currentMessages && chatRoom">
                          <div class="row p-0 m-0" :id="'chatRoomMessages' + chatRoom.id">
                            <div class="col-12 user-messages-msg-messages scroll-width-thin">
                              <div v-for="(message, index) in currentMessages"
                                   :key="index"
                              >
                                <div v-if="(chatRoom.to_user_property && chatRoom.to_user_property !== null) && chatRoom.from_user && (message.from_user_id === chatRoom.to_user_property.user_id)"
                                     :class="user.id !== message.from_user_id ? 'row m-0 p-2 justify-content-start' : 'row m-0 p-2 justify-content-end'"
                                >

                                  <!-- Date and Actions button -->
                                  <div class="col-2 col-xs-2 col-sm-2 col-md-1 col-lg-1 m-0 p-0">
                                    <div class="chat-messages-hour-container">
                                      <span class="chat-messages-hour">
                                        {{( moment.utc(message.created_at).format('DD-MM-YYYY') < moment.utc().format('DD-MM-YYYY') ) ? moment.utc(message.created_at).format('DD-MM YYYY') : moment.utc(message.created_at).format('H:mm')}}
                                      </span>
                                      <span :class="(message.read_at && message.read_at !== null) ? 'chat-messages-hour chat-message-read' : 'chat-messages-hour'">
                                        <font-awesome-icon icon="check-circle" class="icon-fa"/>
                                      </span>
                                    </div>
                                  </div>
                                  <div class="col-10 col-xs-10 col-sm-10 col-md-5 col-lg-5 m-0 p-0"
                                       :class="user.id !== message.from_user_id ? 'chat-messages-container receiver' : 'chat-messages-container sender'"
                                  >
                                    <div class="col-12" style="border-bottom: 1px solid #dee2e6;">
                                      <div class="row m-0 p-0">
                                        <div class="col-2 chat-user-logo">
                                          <div v-if="!chatRoom.to_user_property.user.documents_user_images">
                                            <img :src="require(`@/assets/no-user-image.png`)"
                                                 class="d-block img-fluid user-image-chat"
                                                 alt="..."
                                            >
                                          </div>
                                          <div v-else>
                                            <img :src="`${$s3bucket}${chatRoom.to_user_property.user.documents_user_images.file_path}`" @error="setUserDefaultImg"
                                                 class="d-block img-fluid user-image-chat"
                                                 alt="..."
                                            >
                                          </div>
                                        </div>
                                        <div class="col-10 text-start chat-user-name">
                                          <span class="chat-msg-user">
                                            {{chatRoom.to_user_property.name + ' ' + chatRoom.to_user_property.surname}}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-12 ps-3 pe-3">
                                      <div class="p-1 text-start chat-msg">
                                        <span style="white-space: pre-wrap;">{{message.message}}</span>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div v-else-if="(chatRoom.to_user_service && chatRoom.to_user_service !== null) && chatRoom.from_user && (message.from_user_id === chatRoom.to_user_service.user_id)"
                                     :class="user.id !== message.from_user_id ? 'row m-0 p-2 justify-content-start' : 'row m-0 p-2 justify-content-end'"
                                >

                                  <div class="col-10 col-xs-10 col-sm-10 col-md-5 col-lg-5 m-0 p-0"
                                       :class="user.id !== message.from_user_id ? 'chat-messages-container receiver' : 'chat-messages-container sender'"
                                  >
                                    <div class="col-12" style="border-bottom: 1px solid #dee2e6;">
                                      <div class="row m-0 p-0">
                                        <div class="col-2 chat-user-logo">
                                          <div v-if="!chatRoom.to_user_service.user.documents_user_images">
                                            <img :src="require(`@/assets/no-user-image.png`)"
                                                 class="d-block img-fluid user-image-chat"
                                                 alt="..."
                                            >
                                          </div>
                                          <div v-else>
                                            <img :src="`${$s3bucket}${chatRoom.to_user_service.user.documents_user_images.file_path}`" @error="setUserDefaultImg"
                                                 class="d-block img-fluid user-image-chat"
                                                 alt="..."
                                            >
                                          </div>
                                        </div>
                                        <div class="col-10 text-start chat-user-name">
                                          <span class="chat-msg-user">
                                            {{chatRoom.to_user_service.name + ' ' + chatRoom.to_user_service.surname}}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-12 ps-3 pe-3">
                                      <div class="p-1 text-start chat-msg">
                                        <span style="white-space: pre-wrap;">{{message.message}}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- Date and Actions button -->
                                  <div class="col-2 col-xs-2 col-sm-2 col-md-1 col-lg-1 m-0 p-0">
                                    <div class="chat-messages-hour-container">
                                      <span :class="(message.read_at && message.read_at !== null) ? 'chat-messages-hour chat-message-read' : 'chat-messages-hour'">
                                        <font-awesome-icon icon="check-circle" class="icon-fa"/>
                                      </span>
                                      <span class="chat-messages-hour">
                                        {{( moment.utc(message.created_at).format('DD-MM-YYYY') < moment.utc().format('DD-MM-YYYY') ) ? moment.utc(message.created_at).format('DD-MM YYYY') : moment.utc(message.created_at).format('H:mm')}}
                                      </span>
                                    </div>
                                  </div>

                                </div>

                                <div v-else
                                  :class="user.id !== message.from_user_id ? 'row m-0 p-2 justify-content-start' : 'row m-0 p-2 justify-content-end'"
                                >

                                  <div class="col-10 col-xs-10 col-sm-10 col-md-5 col-lg-5 m-0 p-0"
                                       :class="user.id !== message.from_user_id ? 'chat-messages-container receiver' : 'chat-messages-container sender'"
                                  >
                                    <div class="col-12" style="border-bottom: 1px solid #dee2e6;">
                                      <div class="row m-0 p-0">
                                        <div class="col-2 chat-user-logo">
                                          <div v-if="!chatRoom.from_user.user.documents_user_images">
                                            <img :src="require(`@/assets/no-user-image.png`)"
                                                 class="d-block img-fluid user-image-chat"
                                                 alt="..."
                                            >
                                          </div>
                                          <div v-else>
                                            <img :src="`${$s3bucket}${chatRoom.from_user.user.documents_user_images.file_path}`" @error="setUserDefaultImg"
                                                 class="d-block img-fluid user-image-chat"
                                                 alt="..."
                                            >
                                          </div>
                                        </div>
                                        <div class="col-10 text-start chat-user-name">
                                          <span class="chat-msg-user">
                                            {{chatRoom.from_user.name + ' ' + chatRoom.from_user.surname}}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-12 ps-3 pe-3">
                                      <div class="p-1 text-start chat-msg">
                                        <span style="white-space: pre-wrap;">{{message.message}}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- Date and Actions button -->
                                  <div class="col-2 col-xs-2 col-sm-2 col-md-1 col-lg-1 m-0 p-0">
                                    <div class="chat-messages-hour-container">
                                      <span :class="(message.read_at && message.read_at !== null) ? 'chat-messages-hour chat-message-read' : 'chat-messages-hour'">
                                        <font-awesome-icon icon="check-circle" class="icon-fa"/>
                                      </span>
                                      <span class="chat-messages-hour">
                                        {{( moment.utc(message.created_at).format('DD-MM-YYYY') < moment.utc().format('DD-MM-YYYY') ) ? moment.utc(message.created_at).format('DD-MM YYYY') : moment.utc(message.created_at).format('H:mm')}}
                                      </span>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                            <!-- Form with textarea for new message -->
                            <div class="col-12 position-absolute fixed-bottom zindex-10">
                              <div class="chat-input-position">
                                <form action="javascript:void(0)" method="post" enctype="multipart/form-data">
                                  <div class="form-group col-12">
                                    <div class="input-group input-group-sm mb-3">
                                      <textarea v-model="newMessage"
                                                name="newMessage"
                                                id="newMessage"
                                                rows="1"
                                                cols="50"
                                                maxlength="2000"
                                                class="form-control"
                                                aria-describedby="sendBtnMsg"
                                                @keydown.enter.exact.prevent="sendMessage"
                                                @keydown.enter.shift.exact.prevent="newMessage += '\n'"
                                                :disabled="processingMsg"
                                      />
                                      <button type="submit"
                                              :disabled="processingMsg"
                                              @click.prevent="sendMessage"
                                              class="btn btn-outline-success"
                                              id="sendBtnMsg"
                                      >
                                        <font-awesome-icon icon="arrow-circle-up" class="fa icon"/>
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div v-else class="p-3 m-5">
                          Please chose chat room to see messages
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <small>Please note, we are holding messages history for up to 30 days, afterwards they are cleared so keep notes of important data</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name:"DashboardUserMessages",
  props: ['chat_room_id'],
  data(){
    return {
      user: this.$store.state.auth.user ? this.$store.state.auth.user : null,
      messagesPropertiesGroups: null,
      messagesServicesGroups: null,
      currentMessages: null,
      newMessage: null,
      pagination: null,
      chatRoom: null,
      activeChatRoom: null,
      processing: false,
      processingMsg: false,
      validationErrors: '',
      validationMessage: '',
      validationSuccess: '',
      showError: false,
      newData: '',
      notifications: this.$store.state.notifications ? this.$store.state.notifications.notifications : null
    }
  },
  async mounted() {
    await this.getMessagesPropertiesGroups();
    await this.getMessagesServicesGroups();

    // If we navigated from notifications we want to open correct chatRoom for the user
    this.$nextTick(() => {
      if (this.chat_room_id) {
        // Loop properties so we know which tab should be opened
        let servicesTab = document.getElementById('servicesTabUrl');
        let propertiesTab = document.getElementById('propertiesTabUrl');

        if (this.messagesPropertiesGroups.length > 0) {
          for (let i=0; i<this.messagesPropertiesGroups.length; i++) {
            if (parseInt(this.messagesPropertiesGroups[i].id) === parseInt(this.chat_room_id)) {
              if (propertiesTab && servicesTab) {
                propertiesTab.click();
              }
            }
          }
        }
        // Loop services so we know which tab should be opened
        if (this.messagesServicesGroups.length > 0) {
          for (let i=0; i<this.messagesServicesGroups.length; i++) {
            if (parseInt(this.messagesServicesGroups[i].id) === parseInt(this.chat_room_id)) {
              if (propertiesTab && servicesTab) {
                servicesTab.click();
              }
            }
          }
        }

        let chatRoom = document.getElementById('chatRoom' + this.chat_room_id);
        if (chatRoom) {
          chatRoom.click();
        }
      }
    })
  },
  beforeDestroy() {
    if (this.chatRoom && this.chatRoom.id) {
      let chatRoomMessagesBox = document.querySelector('#chatRoomMessages' + this.chatRoom.id + ' .user-messages-msg-messages');
      if (chatRoomMessagesBox !== null) {
        chatRoomMessagesBox.removeEventListener('scroll', this.handleScroll.bind(null, chatRoomMessagesBox));
      }
    }
  },
  methods: {
    async getMessagesPropertiesGroups() {
      this.processing = true

      await axios.post('api/user/' + this.user.id + '/messages/properties').then(({data}) => {
        this.messagesPropertiesGroups = data;
        this.validationSuccess = data.success;
        this.showError = true;
        const timeout = setTimeout(() => {
          this.showError = false;
          this.validationSuccess = null;
          clearTimeout(timeout);
        }, 4000);
        this.validationErrors = null;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
        // alert(data.message)
      }).finally(() => {
        this.processing = false
      })
    },
    async getMessagesServicesGroups() {
      this.processing = true

      await axios.post('api/user/' + this.user.id + '/messages/services').then(({data}) => {
        this.messagesServicesGroups = data;
        this.validationSuccess = data.success;
        this.showError = true;
        const timeout = setTimeout(() => {
          this.showError = false;
          this.validationSuccess = null;
          clearTimeout(timeout);
        }, 4000);
        this.validationErrors = null;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
        // alert(data.message)
      }).finally(() => {
        this.processing = false
      })
    },
    async getMessages(chat_room_id) {
      this.processing = true

      if (this.chatRoom && this.chatRoom.id) {
        let chatRoomMessagesBox = document.querySelector('#chatRoomMessages' + this.chatRoom.id + ' .user-messages-msg-messages');
        if (chatRoomMessagesBox !== null) {
          chatRoomMessagesBox.removeEventListener('scroll', this.handleScroll.bind(null, chatRoomMessagesBox));
        }
      }

      // Leave current channel
      this.leaveChatChannel();

      await axios.post('api/user/' + this.user.id + '/messages/' + chat_room_id).then(({data}) => {
        // console.log(data);
        this.chatRoom = data['chat_room'][0];
        this.currentMessages = data['messages']['data'];
        this.pagination = data['messages'];
        // If more than 1 page and current messages less than 7 trigger loadMoreMessages
        if (this.pagination && this.pagination.last_page > 1 && this.currentMessages.length < 7) {
          this.loadMoreMessages();
        }
        this.createChatChannel();
        this.validationSuccess = data.success;
        this.showError = true;
        // Scroll newly opened messages to bottom
        const scrollTimeout = setTimeout(() => {
          this.scrollMessagesToBottom();
          clearTimeout(scrollTimeout);
        }, 500);

        const timeout = setTimeout(() => {
          this.showError = false;
          this.validationSuccess = null;
          clearTimeout(timeout);
        }, 4000);
        this.validationErrors = null;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
        // alert(data.message)
      }).finally(() => {
        this.processing = false
        // Set scroll top event listener
        let chatRoomMessagesBox = document.querySelector('#chatRoomMessages' + this.chatRoom.id + ' .user-messages-msg-messages');
        if (chatRoomMessagesBox !== null) {
          chatRoomMessagesBox.addEventListener('scroll', this.handleScroll.bind(null, chatRoomMessagesBox));
        }

        // If more than 1 page and current messages less than 5 trigger loadMoreMessages
        // if (this.pagination && this.pagination.last_page > 1 && this.currentMessages.length < 7) {
        //   this.loadMoreMessages();
        //   let chatRoomMessagesBox = document.querySelector('#chatRoomMessages' + this.chatRoom.id + ' .user-messages-msg-messages');
        //   if (chatRoomMessagesBox !== null) {
        //     chatRoomMessagesBox.scrollTop = 50;
        //   }
        // }
      })
    },
    async handleScroll(element) {
      if (this.processing === false) {
        if (element.scrollTop === 0) {
          await this.loadMoreMessages();
          element.scrollTop = 20;
        }
      }
    },
    async loadMoreMessages() {
      if (this.pagination) {
        let page = this.pagination.current_page - 1 === 0 ? 'stop' : this.pagination.current_page - 1;

        if (page !== 'stop') {
          await axios.post('/api/user/'+ this.user.id + '/messages/' + this.chatRoom.id + '/?page=' + page).then(({data}) => {
            // Loop messages in reverse and add them at the beginning of our messages array
            for (let i = data['messages']['data'].length - 1; i >= 0; i--) {
              this.currentMessages.unshift(data['messages']['data'][i]);
            }
            this.pagination = data['messages'];
            this.validationSuccess = data.success;
            this.showError = true;
            const timeout = setTimeout(() => {
              this.showError = false;
              this.validationSuccess = null;
              clearTimeout(timeout);
            }, 4000);
            this.validationErrors = null;
          }).catch(({response}) => {
            if (response && response.data && response.data.errors) {
              this.validationErrors = response.data.errors;
              this.validationMessage = response.data.message;
            }
            // alert(data.message)
          }).finally(() => {
            this.processing = false
          })
        }
      }
    },
    async sendMessage() {
      if ((this.newMessage === '' || this.newMessage === null) || this.processingMsg === true) {
        return;
      }
      this.processingMsg = true;
      // axios call with this.newMessage value (make sure you do validation in the BE)
      let formData = new FormData();

      let toUserId = (this.chatRoom.to_user_property !== null) ? this.chatRoom.to_user_property.user_id : this.chatRoom.to_user_service.user_id;
      formData.append('chat_room_id', this.chatRoom.id);
      formData.append('from_user_id', this.chatRoom.from_user.user_id);
      formData.append('to_user_id', toUserId);
      formData.append('message', this.newMessage);
      // formData.append('message_type', this.message_type);
      formData.append('property_id', this.chatRoom.property_id);
      formData.append('service_id', this.chatRoom.service_id);

      await axios.post('api/user/' + this.user.id + '/messages/new/' + this.chatRoom.id, formData).then(({data}) => {
        // Clear message input textarea
        this.newMessage = '';
        // Trigger to refresh messages?
        this.validationSuccess = data.success;
        this.showError = true;
        const timeout = setTimeout(() => {
          this.showError = false;
          this.validationSuccess = null;
          clearTimeout(timeout);
        }, 4000);
        this.validationErrors = null;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
        // alert(data.message)
      }).finally(() => {
        this.processingMsg = false;
        this.$nextTick(() => {
          let messageInput = document.getElementById('newMessage');
          if (messageInput) {
            messageInput.focus();
          }
        });
      })
    },
    markMessagesAsRead() {
      axios.post('api/user/' + this.user.id + '/messages/' + this.chatRoom.id + '/read').then(({data}) => {
        // this.markMessageAsRead = true;
        this.validationSuccess = data.success;
        this.showError = true;
        // Scroll newly opened messages to bottom
        const scrollTimeout = setTimeout(() => {
          this.scrollMessagesToBottom();
          clearTimeout(scrollTimeout);
        }, 500);

        const timeout = setTimeout(() => {
          this.showError = false;
          this.validationSuccess = null;
          clearTimeout(timeout);
        }, 4000);
        this.validationErrors = null;
      }).catch(({response}) => {
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        }
        // alert(data.message)
      }).finally(() => {
        this.processing = false
      })
    },
    createChatChannel() {
      let that = this;
      window.Echo.private('Messages.' + this.chatRoom.id) // + this.user.id + '.'
          .listen('.private.message.send', (e) => {
            // console.log('test successful ' + e)
            this.currentMessages.push(e.messages);
            // Scroll newly opened messages to bottom
            const scrollTimeout = setTimeout(() => {
              this.scrollMessagesToBottom();
              clearTimeout(scrollTimeout);
            }, 500);
            // API call to set newly added message as read but only if chat room is opened
            // (we check that by checking for unique chatroom element id in DashboardUserMessages.vue component)
            let chatRoomMessagesContainer = document.getElementById('chatRoomMessages' + this.chatRoom.id);
            if (chatRoomMessagesContainer !== null) {
              this.markMessagesAsRead();
            }
            // this.newData = e.messages;
          })
          .listen('.private.message.read', (e) => {
            // 1 our messages should be marked as read (the ones that were not marked as read)
            // THIS WORKS BUT IS INNEFICIENT FOR BIG NUMBER OF MESSAGES
            if (e.user_id === that.user.id) {
              this.currentMessages.forEach(item => {
                if (parseInt(item.from_user_id) === parseInt(that.user.id) && item.read_at === null) {
                  item.read_at = that.moment();
                }
              })
            } else {
              this.currentMessages.forEach(item => {
                if (parseInt(item.to_user_id) === parseInt(e.user_id) && item.read_at === null) {
                  item.read_at = that.moment();
                }
              })
            }

            // 2 we need to trigger getNotifications() again, that way we get less notifications
            this.$root.$emit('refreshNotifications');
          })
      this.activeChatRoom = this.chatRoom.id;

      // API call to set messages as read
      this.markMessagesAsRead();
    },
    leaveChatChannel() {
      if (this.chatRoom && this.chatRoom.id !== null) {
        window.Echo.leave('Messages.' + this.chatRoom.id);
      }
    },
    checkNotificationsForNewMessages(chatRoomId) {
      if (this.$store.state.notifications && this.$store.state.notifications.notifications.length > 0) {
        for (let i=0; i<this.$store.state.notifications.notifications.length; i++) {
          if ((!this.$store.state.notifications.notifications[i].read_at || this.$store.state.notifications.notifications[i].read_at === null) && parseInt(this.$store.state.notifications.notifications[i].data.chat_room_id) === parseInt(chatRoomId)) {
            return true;
          }
        }
        return false;
      }
    },
    checkSender(user_id, user_to, user_from) {
      if (parseInt(user_id) === parseInt(user_from)) {
        return true
      } else if (parseInt(user_id) === parseInt(user_to)) {
        return false;
      }
    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    setUserDefaultImg(e) {
      e.target.src = require(`@/assets/no-user-image.png`);
    },
    openAdDetails(id) {
      // Open propDetails (AdDetails.vue) to show specific favourite bookmark
      this.$root.$emit('openPropDetailsGlobal', id);
    },
    scrollMessagesToBottom() {
      let element = document.getElementsByClassName("user-messages-msg-messages");
      if (element && element.length > 0) {
        element[0].scrollTop = element[0].scrollHeight;
      }
    },
    // Helpers for LET ads to reflect short-term and long-term lets data
    checkAdIsLetShortTermOrRoom(prop) {
      if (prop && prop.is_let && ((prop.is_short_term && !prop.property_details.studio_room) || prop.is_room)) {
        return true;
      } else {
        return false;
      }
    },
    checkAdIsLetLongTermOrStudio(prop) {
      if (prop && prop.is_let && ((!prop.is_short_term && !prop.is_room) || prop.property_details.studio_room)) {
        return true;
      } else {
        return false;
      }
    },
    showPriceRange(prop) {
      if (this.checkAdIsLetShortTermOrRoom(prop)) {
        return true;
      } else {
        return false;
      }
    },
    showHourPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get hourly prices
        let hourlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 2) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (hourlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateHourPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxHourlyPrice = null;
        let minHourlyPrice = null;

        // get hourly prices
        let hourlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 2) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (hourlyPrices.length > 0) {
          maxHourlyPrice = Math.max.apply(null, hourlyPrices);
          minHourlyPrice = Math.min.apply(null, hourlyPrices);
        }

        if (minHourlyPrice && maxHourlyPrice) {
          if (minHourlyPrice == maxHourlyPrice) {
            return this.convertNumberToCommas(minHourlyPrice);
          } else {
            return this.convertNumberToCommas(minHourlyPrice) + ' - ' + this.convertNumberToCommas(maxHourlyPrice);
          }
        }
      }
    },
    showNightPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get nightly prices
        let nightlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 3) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (nightlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateNightPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxNightlyPrice = null;
        let minNightlyPrice = null;

        // get nightly prices
        let nightlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 3) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (nightlyPrices.length > 0) {
          maxNightlyPrice = Math.max.apply(null, nightlyPrices);
          minNightlyPrice = Math.min.apply(null, nightlyPrices);
        }

        if (minNightlyPrice && maxNightlyPrice) {
          if (minNightlyPrice == maxNightlyPrice) {
            return this.convertNumberToCommas(minNightlyPrice);
          } else {
            return this.convertNumberToCommas(minNightlyPrice) + ' - ' + this.convertNumberToCommas(maxNightlyPrice);
          }
        }
      }
    },
    showWeekPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get weekly prices
        let weeklyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 4) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (weeklyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateWeekPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxWeeklyPrice = null;
        let minWeeklyPrice = null;

        // get weekly prices
        let weeklyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 4) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (weeklyPrices.length > 0) {
          maxWeeklyPrice = Math.max.apply(null, weeklyPrices);
          minWeeklyPrice = Math.min.apply(null, weeklyPrices);
        }

        if (minWeeklyPrice && maxWeeklyPrice) {
          if (minWeeklyPrice == maxWeeklyPrice) {
            return this.convertNumberToCommas(minWeeklyPrice);
          } else {
            return this.convertNumberToCommas(minWeeklyPrice) + ' - ' + this.convertNumberToCommas(maxWeeklyPrice);
          }
        }
      }
    },
    showMonthPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        // get monthly prices
        let monthlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 5) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (monthlyPrices.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    calculateMonthPriceRange(prop) {
      if (prop.property_rooms && prop.property_rooms.length > 0) {
        let maxMonthlyPrice = null;
        let minMonthlyPrice = null;

        // get monthly prices
        let monthlyPrices = prop.property_rooms.filter(obj => {
          if (obj.property_rent_type_id !== 5) {
            return false;
          } else {
            return true;
          }
        }).map(object => {
          return object.price;
        });

        if (monthlyPrices.length > 0) {
          maxMonthlyPrice = Math.max.apply(null, monthlyPrices);
          minMonthlyPrice = Math.min.apply(null, monthlyPrices);
        }

        if (minMonthlyPrice && maxMonthlyPrice) {
          if (minMonthlyPrice == maxMonthlyPrice) {
            return this.convertNumberToCommas(minMonthlyPrice);
          } else {
            return this.convertNumberToCommas(minMonthlyPrice) + ' - ' + this.convertNumberToCommas(maxMonthlyPrice);
          }
        }
      }
    },
    // End Helpers for LET ads to reflect short-term and long-term lets data
  },
  watch: {
    '$store.state.notifications.notifications': {
      handler: function (val) {
        // Reactivity triggered
        this.notifications = [].concat(this.$store.state.notifications.notifications)
        // Reload chat rooms (as most recent needs to get on top)
        this.getMessagesPropertiesGroups();
        this.getMessagesServicesGroups();
      }, deep: true
    },
    // newData() {
    //   this.chatRoom = this.newData['chat_room'][0];
    //   this.currentMessages = this.newData['messages'];
    //   // Scroll refreshed messages to bottom
    //   const scrollTimeout = setTimeout(() => {
    //     this.scrollMessagesToBottom();
    //     clearTimeout(scrollTimeout);
    //   }, 500);
    // }
  }
}
</script>


<style scoped lang="scss">

// Tabs
.dashboard-user-messages {
  .nav-item .nav-link {
    color: #818080;
    &:hover {
      color: #3d3d3d;
    }
  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #3d3d3d;
    background: transparent;
    position: relative;
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active:first-child:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: inherit;
    background: linear-gradient(to left, rgba(128, 0, 128, 0.3), rgba(25, 181, 254, 0.3));
    top: 0;
    left: 0;
  }
}
// End Tabs

.dashboard-header {
  letter-spacing: 2px;
}

.new-message-animation {
  letter-spacing: 1px;
  color: red;
  transition: 1s;
  animation: wiggle 2s infinite;
  -moz-animation: wiggle 2s infinite;
  -o-animation: wiggle 2s infinite;
  -webkit-animation: wiggle 2s infinite;
}

/* Wiggle Animation */
@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}
/* End Wiggle Animation */
.user-messages-wrapper {
  .user-message-container {
    min-height: 68vh;
    max-height: 68vh;
    height: 68vh;
  }
  .user-messages-groups-container {
    min-height: 58vh;
    max-height: 58vh;
    height: 58vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .user-messages-msg-container {
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 65vh;
    max-height: 65vh;
    height: 65vh;
    border-left: 1px solid #dee2e6;
  }
  .user-messages-msg-messages {
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 58vh;
    max-height: 58vh;
    height: 58vh;
    overflow-x: hidden;
    overflow-y: scroll;
    .user-image-chat {
      width: 25px;
      height: 25px;
      margin: auto;
      border-radius: 50%;
      border: 1px solid #9e9e9e;
    }
    .chat-msg-user {
      letter-spacing: 1px;
      //text-transform: uppercase;
      font-family: Georgia, serif !important; // Comic Sans MS, Comic Sans, cursive  Helvetica, sans-serif
    }
  }
  .chat-messages-hour-container {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .chat-messages-hour {
    padding: 2px;
    color: #9e9e9e;
    font-size: 10px;
    display: block;
    margin: auto auto;
  }
  .chat-messages-hour.chat-message-read {
    color: limegreen; // #0d6efd
  }
  .chat-room-info {
    color: #2c3e50;
  }
}

/* *** START CHAT *** */
.chat-list-container {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  background-color: #f1f1f1;
  color: black;
  padding: 5px;
  margin: 10px;
  box-shadow: 0 0.1rem 0.1rem rgb(0 0 0 / 15%);
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}

.chat-list-container:hover {
  background-color: white;
  cursor: pointer;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}

.chat-list-container.active {
  background-color: white !important;
  color: black;
  cursor: pointer;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}

.chat-messages-container.sender {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  background-color: #e1f6ff; // #f1f1f1
  padding: 5px;
  margin: 5px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}
.chat-messages-container.receiver {
    border: 1px solid #dee2e6;
    border-radius: 10px;
    background-color: #eccbff; // #d9f0fa #ffdcff
    padding: 5px;
    margin: 5px;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
}

.chat-messages-person {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  background-color: #f1f1f1;
  padding: 5px;
  margin: 5px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}

.chat-input-position {
  position: relative;
  transform: perspective(1px) translateY(30%);
}

/* Start Chat textarea input and btn */
#newMessage {
  border-radius: 20px 0 0 20px;
}

#sendBtnMsg {
  padding-right: 12px;
  border-radius: 0 20px 20px 0 !important;
}
/* End Chat textarea input and btn */

/* *** END CHAT *** */

/* start tooltip - popups info on icons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
  color: #fff;
}
/* end tooltip - popups info on icons */

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {

}
@media screen and (max-width: 575px) {
  .chat-messages-hour-container .chat-messages-hour {
    font-size: 5px;
  }
  .chat-messages-container .chat-msg {
    font-size: 11px;
  }
  .chat-messages-container .chat-user-logo {
    padding: 5px 0;
    margin: 0;
  }
  .chat-messages-container .chat-user-name {
    padding: 0 5px;
    margin: 0;
    .chat-msg-user {
      font-size: 11px;
    }
  }
  .user-messages-container #chat-room-list .nav-link {
    padding: 2px 4px;
  }
  .user-messages-container .nav {
    flex-wrap: nowrap;
    font-size: 9px;
  }
  .user-name {
    font-size: 9px;
  }
  // Start Make chat rooms horizontal for mobiles
  .chat-list-container {
    overflow: hidden;
    margin: 5px 0 5px 0;
    padding: 2px;
    display: inline-block;
    width: 70px;
    height: 60px;
  }
  .user-messages-container {
    height: auto;
    min-height: auto;
  }
  .user-messages-msg-messages {
    height: 58vh;
    min-height: 58vh;
    max-height: 58vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .user-messages-container .chat-room {
    margin: 0 2px;
    width: 70px;
    height: 70px;
    display: inline-block;
    overflow: hidden;
  }
  .user-messages-container .user-messages-groups-container {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    height: 80px;
    min-height: 80px;
    max-height: 80px;
  }
  .user-messages-container .user-image {
    width: 30px;
    height: auto;
  }
  .user-messages-container .chat-list-person {
    white-space: normal;
    position: relative;
  }
  .user-messages-container .chat-list-person .new-message-animation {
    font-size: 8px;
    position: absolute;
    top: -2px;
    left: 13px;
    background-color: white;
    padding: 2px;
    border-radius: 10px;
  }
  // End Make chat rooms horizontal for mobiles
}
@media screen and (min-width: 576px) {
  .chat-messages-hour-container .chat-messages-hour {
    font-size: 5px;
  }
  .chat-messages-container .chat-msg {
    font-size: 11px;
  }
  .chat-messages-container .chat-user-logo {
    padding: 5px 0;
    margin: 0;
  }
  .chat-messages-container .chat-user-name {
    padding: 0 5px;
    margin: 0;
    .chat-msg-user {
      font-size: 11px;
    }
  }
  .user-messages-container #chat-room-list .nav-link {
    padding: 5px 10px;
  }
  .user-messages-container .nav {
    flex-wrap: nowrap;
    font-size: 9px;
  }
  .user-name {
    font-size: 9px;
  }
  // Start Make chat rooms horizontal for mobiles
  .chat-list-container {
    overflow: hidden;
    margin: 5px 0 5px 0;
    padding: 2px;
    display: inline-block;
    width: 70px;
    height: 60px;
  }
  .user-messages-container {
    height: auto;
    min-height: auto;
  }
  .user-messages-container .chat-room {
    margin: 0 2px;
    width: 70px;
    height: 70px;
    display: inline-block;
    overflow: hidden;
  }
  .user-messages-container .user-messages-groups-container {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    height: 80px;
    min-height: 80px;
    max-height: 80px;
  }
  .user-messages-container .user-image {
    width: 30px;
    height: auto;
  }
  .user-messages-container .chat-list-person {
    white-space: normal;
    position: relative;
  }
  .user-messages-container .chat-list-person .new-message-animation {
    font-size: 8px;
    position: absolute;
    top: -2px;
    left: 13px;
    background-color: white;
    padding: 2px;
    border-radius: 10px;
  }
  // End Make chat rooms horizontal for mobiles
}
@media screen and (min-width: 768px) {
  .chat-messages-hour-container .chat-messages-hour {
    font-size: 10px;
  }
  .chat-messages-container .chat-msg {
    font-size: 12px;
  }
  .chat-messages-container .chat-user-logo {
    padding: 5px 0;
    margin: 0;
  }
  .chat-messages-container .chat-user-name {
    padding: 0 5px;
    margin: 0;
    .chat-msg-user {
      font-size: 12px;
    }
  }
  .user-messages-container #chat-room-list .nav-link {
    padding: 8px 16px;
  }
  .user-messages-container .nav {
    flex-wrap: nowrap;
    font-size: 10px;
  }
  .user-name {
    font-size: 12px;
  }
  // Start Make chat rooms horizontal for mobiles
  .chat-list-container {
    overflow: hidden;
    margin: 5px 0 5px 0;
    padding: 2px;
    display: block; //
    width: auto; //
    height: auto; //
    min-height: 200px;
  }
  .user-messages-container {
    min-height: 68vh; //
    max-height: 68vh; //
    height: 68vh; //
  }
  .user-messages-wrapper .chat-room {
    margin: 0 2px;
    width: auto; //
    height: auto; //
    min-height: 200px;
    display: block; //
    overflow: hidden;
  }
  .user-messages-container .user-messages-groups-container {
    white-space: normal; //
    min-height: 58vh; //
    max-height: 58vh; //
    height: 58vh; //
    overflow-x: hidden; //
    overflow-y: scroll; //
  }
  .user-messages-container .user-image {
    width: 120px; //
    height: auto; //
  }
  .user-messages-container .chat-list-person {
    white-space: normal;
    position: relative;
  }
  .user-messages-container .chat-list-person .new-message-animation {
    font-size: 16px; //
    position: inherit; //
    top: unset; //
    left: unset; //
    background-color: unset; //
  }
  // End Make chat rooms horizontal for mobiles
}
@media screen and (min-width: 992px) {
  .chat-messages-container .chat-msg {
    font-size: 16px;
  }
  .chat-messages-container .chat-user-logo {
    padding: 5px 5px;
    margin: 0;
  }
  .chat-messages-container .chat-user-name {
    padding: 5px 5px;
    margin: 0;
    .chat-msg-user {
      font-size: 16px;
    }
  }
  .user-messages-container .nav {
    flex-wrap: wrap;
    font-size: 16px;
  }
  .user-name {
    font-size: 16px;
  }
}
@media screen and (min-width: 1200px) {

}
@media screen and (min-width: 1920px) {

}

</style>